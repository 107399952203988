import { getUserId } from '@src/utils/helpers/authentication';
import {
  getPercentsPerPortion,
  getKcalsPerPortionSum
} from '@src/utils/helpers/calories';
import localize from '@src/i18n';
import tracking from '@src/utils/helpers/tracking/tracking';
import { recipeCategory } from '@src/utils/helpers/tracking/eventproperties';
import { userRecipesActions } from '@ducks/userRecipes';
import { recipeTagsActions } from '@ducks/recipeTags';
import { errorActions, errorSelectors } from '@ducks/error';
import { modalsActions } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import types from './types';

const recipeIngredientsUpdate = (ingredients, recipe, portions) => {
  const portionsAmount = +portions || 1;
  const kcalPerUnit = getKcalsPerPortionSum(ingredients, portionsAmount);
  const percentagePerPortion = getPercentsPerPortion(
    ingredients,
    portionsAmount
  );
  return {
    type: types.RECIPE_INGREDIENTS_UPDATE,
    updatedRecipe: {
      ...recipe,
      kcalPerUnit,
      energyDistribution: {
        energyPercentageFromProtein: percentagePerPortion.protein,
        energyPercentageFromFat: percentagePerPortion.fat,
        energyPercentageFromCarbs: percentagePerPortion.carbs,
        energyPercentageFromAlcohol: percentagePerPortion.alcohol
      }
    }
  };
};

const recipeIdReset = () => ({
  type: types.RESET_RECIPE_ID
});

const recipeReset = () => ({
  type: types.RESET_RECIPE
});

const recipePending = () => ({
  type: types.RECIPE_PENDING
});

const recipeFulfilled = response => ({
  type: types.RECIPE_FULFILLED,
  response
});

const recipeRejected = error => ({
  type: types.RECIPE_REJECTED,
  error
});

const recipeHashPending = () => ({
  type: types.RECIPE_HASH_PENDING
});

const recipeHashFulfilled = response => ({
  type: types.RECIPE_HASH_FULFILLED,
  response
});

const recipeHashRejected = error => ({
  type: types.RECIPE_HASH_REJECTED,
  error
});

function getRecipeItem(
  recipeId,
  shareToken,
  afterFulfilled,
  trackingFunction,
  trackingParams
) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(recipeFulfilled(response));
      if (response.tags) {
        const flattenedTags = Object.values(response.tags).flat();
        dispatch(recipeTagsActions.selectRecipeTags(flattenedTags));
      }
      trackingFunction?.({
        ...trackingParams,
        recipeName: response.name,
        recipeId: response.id
      });
      afterFulfilled?.(); // used to trigger saved recipe disclaimer popup after loading recipe to avoid ugly refreshing with popup open
    };
    const rejected = error => {
      dispatch(recipeRejected(error));
      dispatch(errorActions.errorHandler(error));
      trackingFunction?.({ ...trackingParams });
    };

    dispatch(recipePending());

    return wsCalls().getRecipe(
      fulfilled,
      rejected,
      getUserId(),
      recipeId,
      shareToken
    );
  };
}

function getRecipeShareHash(recipeId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(recipeHashFulfilled(response));
      dispatch(modalsActions.showModal(modals.SHARE_RECIPE_MODAL, response));
      tracking.trackShareRecipeDisclaimerPageView(recipeCategory.userRecipe);
    };
    const rejected = error => {
      dispatch(recipeHashRejected(error));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(recipeHashPending());

    return wsCalls().getRecipeShareHash(
      fulfilled,
      rejected,
      getUserId(),
      recipeId
    );
  };
}

const importSharedRecipePending = () => ({
  type: types.IMPORT_SHARED_RECIPE_PENDING
});

const importSharedRecipeFulfilled = recipeId => ({
  type: types.IMPORT_SHARED_RECIPE_FULFILLED,
  importedRecipeId: recipeId
});

const importSharedRecipeRejected = () => ({
  type: types.IMPORT_SHARED_RECIPE_REJECTED
});

const postRecipePending = () => ({
  type: types.POST_RECIPE_PENDING
});

const postRecipeFulfilled = recipeId => ({
  type: types.POST_RECIPE_FULFILLED,
  id: recipeId
});

const postRecipeRejected = () => ({
  type: types.POST_RECIPE_REJECTED
});

const postRecipeReportPending = () => ({
  type: types.POST_RECIPE_REPORT_PENDING
});

const postRecipeReportFulfilled = recipeId => ({
  type: types.POST_RECIPE_REPORT_FULFILLED,
  id: recipeId
});

const postRecipeReportRejected = () => ({
  type: types.POST_RECIPE_REPORT_REJECTED
});

const putRecipePending = () => ({
  type: types.PUT_RECIPE_PENDING
});

const putRecipeFulfilled = recipeId => ({
  type: types.PUT_RECIPE_FULFILLED,
  id: recipeId
});

const putRecipeRejected = () => ({
  type: types.PUT_RECIPE_REJECTED
});

const deleteRecipePending = () => ({
  type: types.DELETE_RECIPE_PENDING
});

const deleteRecipeFulfilled = recipeId => ({
  type: types.DELETE_RECIPE_FULFILLED,
  id: recipeId
});

const deleteRecipeRejected = () => ({
  type: types.DELETE_RECIPE_REJECTED
});

const postRecipeImagePending = () => ({
  type: types.POST_RECIPE_IMAGE_PENDING
});

const postRecipeImageFulfilled = () => ({
  type: types.POST_RECIPE_IMAGE_FULFILLED
});

const postRecipeImageRejected = () => ({
  type: types.POST_RECIPE_IMAGE_REJECTED
});

const putRecipeFoodTypePending = () => ({
  type: types.PUT_RECIPE_FOODTYPE_PENDING
});

const putRecipeFoodTypeFulfilled = () => ({
  type: types.PUT_RECIPE_FOODTYPE_FULFILLED
});

const putRecipeFoodTypeRejected = () => ({
  type: types.PUT_RECIPE_FOODTYPE_REJECTED
});

const getRatingPending = () => ({
  type: types.GET_RATING_PENDING
});

const getRatingFulfilled = response => ({
  type: types.GET_RATING_FULFILLED,
  response
});

const getRatingRejected = () => ({
  type: types.GET_RATING_REJECTED
});

const putRatingPending = () => ({
  type: types.PUT_RATING_PENDING
});

const putRatingFulfilled = () => ({
  type: types.PUT_RATING_FULFILLED
});

const putRatingRejected = () => ({
  type: types.PUT_RATING_REJECTED
});

const deleteRatingPending = () => ({
  type: types.DELETE_RATING_PENDING
});

const deleteRatingFulfilled = () => ({
  type: types.DELETE_RATING_FULFILLED
});

const deleteRatingRejected = () => ({
  type: types.DELETE_RATING_REJECTED
});

const setUserRating = (id, rating) => ({
  type: types.SET_USER_RATING,
  id,
  rating
});

// Set rating with posted data to not having to rely on
// getting rating value from log response.
// This enables setting the button as selected faster.
const putRatingFulfilledThunk = (id, rating) => dispatch => {
  dispatch(setUserRating(id, rating));
  dispatch(putRatingFulfilled());
};

const deleteRatingFulfilledThunk = id => dispatch => {
  dispatch(setUserRating(id, 0));
  dispatch(deleteRatingFulfilled());
};

function postRecipeImage(payload, recipeId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = resp => {
      dispatch(postRecipeImageFulfilled());
      dispatch(postRecipeFulfilled(recipeId));
      return resp;
    };
    const rejected = error => {
      dispatch(postRecipeImageRejected());
      dispatch(postRecipeFulfilled(recipeId));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(postRecipeImagePending());

    return wsCalls().postRecipeImage(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      recipeId
    );
  };
}

function postRecipe(
  payload,
  imagePayload,
  makeSystemRecipe,
  recipeName,
  isCopyMode
) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = recipeId => {
      if (isCopyMode) tracking.trackRecipeCopied(recipeName);
      else tracking.trackRecipeCreated(recipeName);
      if (makeSystemRecipe) dispatch(putRecipeFoodType(recipeId));
      if (imagePayload) {
        dispatch(postRecipeImage(imagePayload, recipeId));
      } else dispatch(postRecipeFulfilled(recipeId));
    };
    const rejected = error => {
      dispatch(postRecipeRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(postRecipePending());

    return wsCalls().postRecipe(fulfilled, rejected, payload, getUserId());
  };
}

function postRecipeReport(payload, objectCategory) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = recipeId => {
      tracking.trackRecipeIssueReported(objectCategory);
      dispatch(postRecipeReportFulfilled(recipeId));
    };
    const rejected = error => {
      dispatch(postRecipeReportRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(postRecipeReportPending());

    return wsCalls().postRecipeReport(
      fulfilled,
      rejected,
      payload,
      getUserId()
    );
  };
}

function importSharedRecipe(
  recipeBaseUrl,
  payload,
  trackingFunction,
  trackingParams
) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = recipeId => {
      dispatch(
        getRecipeItem(
          recipeId,
          false,
          undefined,
          () =>
            dispatch(
              modalsActions.showModal(modals.ALERT_MODAL, {
                action: () => {},
                header: localize('popup_title_shared_recipe_saved'),
                content: localize('popup_message_shared_recipe_saved'),
                confirmationOnly: true,
                confirmationLabel: localize('action_ok_close')
              })
            ),
          trackingFunction,
          trackingParams
        )
      );
      dispatch(importSharedRecipeFulfilled(recipeId));
    };
    const rejected = error => {
      dispatch(importSharedRecipeRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(importSharedRecipePending());

    return wsCalls().importSharedRecipe(
      fulfilled,
      rejected,
      payload,
      getUserId()
    );
  };
}

function putRecipe(
  payload,
  imagePayload,
  recipeId,
  makeSystemRecipe,
  recipeName
) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => {
      tracking.trackRecipeUpdated(recipeName);
      if (makeSystemRecipe) dispatch(putRecipeFoodType(recipeId));
      if (imagePayload) {
        dispatch(postRecipeImage(imagePayload, recipeId));
      } else dispatch(putRecipeFulfilled(recipeId));
    };
    const rejected = error => {
      dispatch(putRecipeRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(putRecipePending());

    return wsCalls().putRecipe(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      recipeId
    );
  };
}

function putRecipeFoodType(recipeId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(putRecipeFoodTypeFulfilled());
    const rejected = error => {
      dispatch(putRecipeFoodTypeRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(putRecipeFoodTypePending());

    return wsCalls().putRecipeFoodType(
      fulfilled,
      rejected,
      getUserId(),
      recipeId
    );
  };
}

function deleteRecipe(recipeId, afterFulfilled) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => {
      tracking.trackRecipeDeleted();
      dispatch(deleteRecipeFulfilled(recipeId));
      dispatch(userRecipesActions.getUserRecipes());
      dispatch(
        modalsActions.showModal(modals.CONFIRMATION_MODAL, {
          text: localize('label_recipe_deleted')
        })
      );
      afterFulfilled?.();
    };
    const rejected = error => {
      dispatch(deleteRecipeRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(deleteRecipePending());

    return wsCalls().deleteRecipe(fulfilled, rejected, getUserId(), recipeId);
  };
}

function getRecipeRating(recipeId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(getRatingFulfilled(response));
    const rejected = error => {
      dispatch(getRatingRejected());
      if (errorSelectors.statusLens(error) !== 404) {
        dispatch(errorActions.errorHandler(error));
      }
    };

    dispatch(getRatingPending());

    return wsCalls().getRecipeRating(
      fulfilled,
      rejected,
      getUserId(),
      recipeId
    );
  };
}

function putRecipeRating(recipeId, rating) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => {
      dispatch(putRatingFulfilledThunk(recipeId, rating));
      dispatch(
        modalsActions.showModal(modals.CONFIRMATION_MODAL, {
          text: localize('label_recipe_rating_updated')
        })
      );
    };
    const rejected = error => {
      dispatch(putRatingRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(putRatingPending());

    return wsCalls().putRecipeRating(
      fulfilled,
      rejected,
      rating,
      getUserId(),
      recipeId
    );
  };
}

function deleteRecipeRating(recipeId, shareToken) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => {
      dispatch(deleteRatingFulfilledThunk(recipeId));
      dispatch(
        modalsActions.showModal(modals.CONFIRMATION_MODAL, {
          text: localize('label_recipe_rating_deleted')
        })
      );
    };
    const rejected = error => {
      dispatch(deleteRatingRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(deleteRatingPending());

    return wsCalls().deleteRecipeRating(
      fulfilled,
      rejected,
      getUserId(),
      recipeId,
      shareToken
    );
  };
}

function updateRecipeRating(recipeId, rating, ratingToDelete, shareToken) {
  return dispatch =>
    dispatch(
      ratingToDelete
        ? deleteRecipeRating(recipeId, shareToken)
        : putRecipeRating(recipeId, rating)
    ).then(() => {
      dispatch(getRecipeRating(recipeId));
    });
}

function putRecipeRatingAndGetRecipe(
  recipeId,
  rating,
  ratingToDelete,
  shareToken
) {
  return dispatch =>
    dispatch(
      ratingToDelete
        ? deleteRecipeRating(recipeId, shareToken)
        : putRecipeRating(recipeId, rating)
    ).then(() => {
      dispatch(getRecipeItem(recipeId));
    });
}

export default {
  getRecipeItem,
  getRecipeShareHash,
  putRecipeRatingAndGetRecipe,
  importSharedRecipe,
  postRecipeReport,
  postRecipe,
  putRecipe,
  putRecipeFoodType,
  updateRecipeRating,
  deleteRecipe,
  recipeIngredientsUpdate,
  recipeReset,
  recipeIdReset,
  getRecipeRating
};
