export default {
  RECIPE_SEARCH_PENDING: 'recipeSearch/RECIPE_SEARCH_PENDING',
  RECIPE_SEARCH_FULFILLED: 'recipeSearch/RECIPE_SEARCH_FULFILLED',
  RECIPE_SEARCH_REJECTED: 'recipeSearch/RECIPE_SEARCH_REJECTED',
  RESET_SEARCH_RESULT: 'recipeSearch/RESET_SEARCH_RESULT',
  SET_SEARCH_INPUT_VALUE: 'recipeSearch/SET_SEARCH_INPUT_VALUE',
  SET_RAW_SEARCH_INPUT_VALUE: 'recipeSearch/SET_RAW_SEARCH_INPUT_VALUE',
  RESET_RAW_SEARCH_INPUT_VALUE: 'recipeSearch/RESET_RAW_SEARCH_INPUT_VALUE',
  RESET_INPUT_VALUE: 'recipeSearch/RESET_INPUT_VALUE'
};
