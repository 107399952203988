import constants from '@src/utils/constants/forms';
import { modalsTypes } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import types from './types';
import initialState from './initialState';

const plusMinusReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_VALUE: {
      return { ...state, value: validator(action.value) };
    }
    case modalsTypes.HIDE_MODAL: {
      if (action.id === modals.LOG_RECIPE_MODAL_ID) {
        return { ...state, value: initialState.value };
      }
      return state;
    }
    case types.RESET_VALUE: {
      return initialState;
    }
    default:
      return state;
  }
};

const validator = value => {
  if (value > constants.PLUS_MINUS_MAX) {
    return constants.PLUS_MINUS_MAX;
  }
  if (value < constants.PLUS_MINUS_MIN) {
    return constants.PLUS_MINUS_MIN;
  }
  return value;
};

export default plusMinusReducer;
