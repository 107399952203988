import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const setInputValue = value => ({
  type: types.SET_SEARCH_INPUT_VALUE,
  value
});

const setRawSearchInputValue = value => ({
  type: types.SET_RAW_SEARCH_INPUT_VALUE,
  value
});

const clearInputValue = () => ({
  type: types.RESET_INPUT_VALUE
});

const clearRawSearchInputValue = () => ({
  type: types.RESET_RAW_SEARCH_INPUT_VALUE
});

const clearSearchResult = () => ({
  type: types.RESET_SEARCH_RESULT
});

const foodSearchPending = () => ({
  type: types.RECIPE_SEARCH_PENDING
});

const foodSearchFulfilled = (response, searchString, recipeSearchPage) => ({
  type: types.RECIPE_SEARCH_FULFILLED,
  response,
  searchString,
  recipeSearchPage
});

const foodSearchRejected = () => ({
  type: types.RECIPE_SEARCH_REJECTED
});

function getSearchResult({
  searchString,
  rawSearchString,
  product,
  filters,
  maxResult = 12,
  isFeatured,
  recipeSearchPage = 1,
  wantedResponseAttributes
}) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response =>
      dispatch(foodSearchFulfilled(response, searchString, recipeSearchPage));
    const rejected = error => {
      dispatch(foodSearchRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(foodSearchPending());

    if (rawSearchString)
      return wsCalls().postRecipesRawSearch(
        fulfilled,
        rejected,
        rawSearchString,
        getUserId(),
        searchString,
        maxResult,
        wantedResponseAttributes
      );

    if (isFeatured)
      return wsCalls().getFeaturedRecipes(
        fulfilled,
        rejected,
        getUserId(),
        maxResult * recipeSearchPage
      );

    return wsCalls().getRecipesAdvanced(
      fulfilled,
      rejected,
      getUserId(),
      product,
      filters,
      maxResult * recipeSearchPage
    );
  };
}

export default {
  getSearchResult,
  clearSearchResult,
  setInputValue,
  setRawSearchInputValue,
  clearRawSearchInputValue,
  clearInputValue
};
