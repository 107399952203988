import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const getPartialGoalPending = () => ({
  type: types.GET_PARTIAL_GOAL_PENDING
});

const getPartialGoalFulfilled = response => ({
  type: types.GET_PARTIAL_GOAL_FULFILLED,
  response
});

const getPartialGoalRejected = () => ({
  type: types.GET_PARTIAL_GOAL_REJECTED
});

function getPartialGoal(params) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(getPartialGoalFulfilled(response));
    };
    const rejected = error => {
      dispatch(getPartialGoalRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(getPartialGoalPending());

    return wsCalls().getPartialGoal(fulfilled, rejected, getUserId(), params);
  };
}

export default {
  getPartialGoal
};
