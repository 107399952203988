import types from './types';

const addImage = value => ({
  type: types.ADD_IMAGE,
  value
});

const deleteImage = () => ({
  type: types.DELETE_IMAGE
});

export default {
  addImage,
  deleteImage
};
