export default {
  PROGRAM_GOAL_PENDING: 'programGoal/PROGRAM_GOAL_PENDING',
  PROGRAM_GOAL_FULFILLED: 'programGoal/PROGRAM_GOAL_FULFILLED',
  PROGRAM_GOAL_REJECTED: 'programGoal/PROGRAM_GOAL_REJECTED',
  WEIGHTPLAN_TREND_PENDING: 'programGoal/WEIGHTPLAN_TREND_PENDING',
  WEIGHTPLAN_TREND_FULFILLED: 'programGoal/WEIGHTPLAN_TREND_FULFILLED',
  WEIGHTPLAN_TREND_REJECTED: 'programGoal/WEIGHTPLAN_TREND_REJECTED',
  WEIGHT_TREND_PENDING: 'programGoal/WEIGHT_TREND_PENDING',
  WEIGHT_TREND_FULFILLED: 'programGoal/WEIGHT_TREND_FULFILLED',
  WEIGHT_TREND_REJECTED: 'programGoal/WEIGHT_TREND_REJECTED',
  PROGRAM_WEIGHT_TREND_PENDING: 'programGoal/PROGRAM_WEIGHT_TREND_PENDING',
  PROGRAM_WEIGHT_TREND_FULFILLED: 'programGoal/PROGRAM_WEIGHT_TREND_FULFILLED',
  PROGRAM_WEIGHT_TREND_REJECTED: 'programGoal/PROGRAM_WEIGHT_TREND_REJECTED',
  WAIST_TREND_PENDING: 'programGoal/WAIST_TREND_PENDING',
  WAIST_TREND_FULFILLED: 'programGoal/WAIST_TREND_FULFILLED',
  WAIST_TREND_REJECTED: 'programGoal/WAIST_TREND_REJECTED'
};
