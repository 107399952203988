import types from './types';

const addInstruction = (value, instructionType) => ({
  type: types.ADD_INSTRUCTION,
  value,
  instructionType
});

const deleteInstruction = id => ({
  type: types.DELETE_INSTRUCTION,
  id
});

const editInstruction = ({ id, text }) => ({
  type: types.EDIT_INSTRUCTION,
  id,
  text
});

const reorderInstructions = items => ({
  type: types.REORDER_INSTRUCTIONS,
  items
});

export default {
  addInstruction,
  editInstruction,
  deleteInstruction,
  reorderInstructions
};
