import reject from 'lodash/reject';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import initialState from './initialState';

const getNextAchievementIndex = achievements =>
  findIndex(achievements, {
    achieved: false
  });

// Get rid of percentageGoal if not already achieved since we shouldn't show it to user.
const filterGoals = partialGoals =>
  filter(
    partialGoals,
    item =>
      !(
        item.partialGoalType === 'WEIGHT_LOSS_IN_PERCENT' &&
        item.achieved === false
      )
  );

const activeGoal = partialGoals => {
  const filteredGoalsArray = filterGoals(partialGoals);

  const targetAchievementIndex = getNextAchievementIndex(filteredGoalsArray);

  return filteredGoalsArray[targetAchievementIndex];
};

const hasPartialGoals = partialGoals => !!(partialGoals && partialGoals.length);

const currentPartialGoals = partialGoals => {
  if (!hasPartialGoals(partialGoals)) {
    return initialState.partialGoals;
  }

  let filteredGoalsArray;

  filteredGoalsArray = filterGoals(partialGoals);

  const targetAchievementIndex = getNextAchievementIndex(filteredGoalsArray);

  // Extract goals - all achieved, the active and one upcoming.
  if (
    targetAchievementIndex !== -1 &&
    targetAchievementIndex !== partialGoals.length - 1
  ) {
    filteredGoalsArray = filteredGoalsArray.slice(
      0,
      targetAchievementIndex + 2
    );
  }

  return reject(filteredGoalsArray, 'achieved');
};

const completedPartialGoals = partialGoals => filter(partialGoals, 'achieved');

const firstPartialGoal = partialGoals => {
  const goals = currentPartialGoals(partialGoals);

  return goals && goals.length ? [goals[0]] : initialState.partialGoals;
};

export default {
  hasPartialGoals,
  currentPartialGoals,
  completedPartialGoals,
  firstPartialGoal,
  activeGoal
};
