import { isItWellobe as isWellobe } from '@src/utils/helpers/isWellobe';
import config from '../../config';
import wellobe from './cdn-assets/wellobe.json';
import vektklubb from './cdn-assets/vektklubb.json';

const data = isWellobe(config.product) ? wellobe : vektklubb;

export default {
  description: data.description,
  info: data.info,
  ctaText: data.ctaText
};
