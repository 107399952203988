import types from './types';

const setValue = value => ({
  type: types.SET_VALUE,
  value
});

const resetValue = () => ({
  type: types.RESET_VALUE
});

export default {
  setValue,
  resetValue
};
