import {
  getPossibleConsents,
  getUserConsents as getUserConsentsFromApi,
  setConsent
} from '@src/utils/helpers/wsCalls';
import { errorActions } from '@ducks/error';
import types from './types';

const getAllConsentsFulfilled = response => ({
  type: types.GET_ALL_CONSENTS_FULFILLED,
  response
});

const getAllConsentsRejected = () => ({
  type: types.GET_ALL_CONSENTS_REJECTED
});

const getUserConsentsFulfilled = response => ({
  type: types.GET_USER_CONSENTS_FULFILLED,
  response
});

const getUserConsentsRejected = () => ({
  type: types.GET_USER_CONSENTS_REJECTED
});

const putUserConsentsFulfilled = response => ({
  type: types.PUT_USER_CONSENTS_FULFILLED,
  response
});

const putUserConsentsRejected = () => ({
  type: types.PUT_USER_CONSENTS_REJECTED
});

const putUserConsentsPending = () => ({
  type: types.PUT_USER_CONSENTS_PENDING
});

function getUserConsents(info) {
  return dispatch => {
    const fulfilled = response => dispatch(getUserConsentsFulfilled(response));
    const rejected = () => {
      dispatch(getUserConsentsRejected());
    };

    return getUserConsentsFromApi(fulfilled, rejected, info.userId, info.sig);
  };
}

function getAllConsents(info) {
  return dispatch => {
    const fulfilled = response => dispatch(getAllConsentsFulfilled(response));
    const rejected = () => {
      dispatch(getAllConsentsRejected());
    };

    return getPossibleConsents(fulfilled, rejected, info.sig);
  };
}

function putUserConsent(value, consentId, info) {
  return dispatch => {
    const fulfilled = () => dispatch(putUserConsentsFulfilled());

    const rejected = error => {
      dispatch(putUserConsentsRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(putUserConsentsPending());

    return setConsent(
      fulfilled,
      rejected,
      info.userId,
      info.sig,
      consentId,
      value
    );
  };
}

export default {
  putUserConsent,
  getUserConsents,
  getAllConsents
};
