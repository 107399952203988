import { recipeTypes } from '@ducks/recipe';
import requestStatus from '@src/utils/helpers/requestStatus';

import flatten from 'lodash/flatten';
import types from './types';
import initialState from './initialState';

const allTagsFlattened = tagsSections =>
  flatten(tagsSections.map(section => section.tags));

const tagById = (tagsSections, tagId) =>
  allTagsFlattened(tagsSections).find(tag => tag.id === tagId);

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RECIPE_TAGS_PENDING: {
      return { ...state, getStatus: requestStatus.pending };
    }
    case types.GET_RECIPE_TAGS_FULFILLED: {
      return {
        ...state,
        getStatus: requestStatus.fulfilled,
        areTagsSelected: false,
        tags: action.response
      };
    }
    case types.GET_RECIPE_TAGS_REJECTED: {
      return { ...state, getStatus: requestStatus.rejected };
    }
    case types.TOGGLE_SYSTEM_RECIPE: {
      return { ...state, systemRecipe: !state.systemRecipe };
    }
    case types.SELECT_RECIPE_TAGS: {
      return {
        ...state,
        areTagsSelected: true,
        tags: Object.keys(state.tags).map(key => ({
          ...state.tags[key],
          tags: state.tags[key].tags.map(tag => {
            const { id: tagId } = tag;
            const { tags: actionTags = [] } = action;
            const isSelected = actionTags.map(t => t.id).includes(tagId);
            return {
              ...tag,
              selected: isSelected
            };
          })
        }))
      };
    }
    case types.TOGGLE_RECIPE_TAGS: {
      const {
        tag: {
          id: actionTagId,
          stdName: actionTagStdName,
          parentId: actionTagParentTagId,
          selected: actionTagWasSelected,
          type: actionTagType
        } = {}
      } = action;

      const parentTagStdName = tagById(
        state.tags,
        actionTagParentTagId
      )?.stdName;

      return {
        ...state,
        tags: Object.keys(state.tags).map(key => ({
          ...state.tags[key],
          tags: state.tags[key].tags.map(tag => {
            const {
              id: tagId,
              stdName: tagStdName,
              selected: tagIsSelected
            } = tag;
            const isSameTag = tagId === actionTagId;
            const hasSameStdName =
              tagStdName && actionTagStdName && tagStdName === actionTagStdName;
            const isParentTag = tagId === actionTagParentTagId;
            const hasSameStdNameAsToBeSelectedParentTag =
              parentTagStdName === tag.stdName &&
              !tagIsSelected &&
              !actionTagWasSelected;
            const isParentTagToBeSelected =
              isParentTag && !tagIsSelected && !actionTagWasSelected;
            const isAllergenSelected = actionTagType === 'allergensAndDislikes';

            return isSameTag ||
              (hasSameStdName && !isAllergenSelected) ||
              isParentTagToBeSelected ||
              hasSameStdNameAsToBeSelectedParentTag
              ? {
                  ...tag,
                  selected: !actionTagWasSelected,
                  type: state.tags[key].tagType
                }
              : tag;
          })
        }))
      };
    }
    case recipeTypes.RESET_RECIPE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default tagsReducer;
