import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const toggleRecipeTag = tag => ({
  type: types.TOGGLE_RECIPE_TAGS,
  tag
});

const selectRecipeTags = tags => ({
  type: types.SELECT_RECIPE_TAGS,
  tags
});

const toggleSystemRecipe = () => ({
  type: types.TOGGLE_SYSTEM_RECIPE
});

const getPending = () => ({
  type: types.GET_RECIPE_TAGS_PENDING
});

const getFulfilled = response => ({
  type: types.GET_RECIPE_TAGS_FULFILLED,
  response
});

const getRejected = () => ({
  type: types.GET_RECIPE_TAGS_REJECTED
});

function getTags() {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(getFulfilled(response));
    const rejected = error => {
      dispatch(getRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(getPending());

    return wsCalls().getRecipeTags(fulfilled, rejected, getUserId());
  };
}

export default {
  getTags,
  toggleRecipeTag,
  toggleSystemRecipe,
  selectRecipeTags
};
