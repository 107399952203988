import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const programGoalPending = () => ({
  type: types.PROGRAM_GOAL_PENDING
});

const programGoalFulfilled = response => ({
  type: types.PROGRAM_GOAL_FULFILLED,
  response
});

const programGoalRejected = () => ({
  type: types.PROGRAM_GOAL_REJECTED
});

function getProgramGoal() {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(programGoalFulfilled(response));
    const rejected = error => {
      dispatch(programGoalRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(programGoalPending());

    return wsCalls().getProgramGoal(fulfilled, rejected, getUserId());
  };
}

const weightplanTrendPending = () => ({
  type: types.WEIGHTPLAN_TREND_PENDING
});

const weightplanTrendFulfilled = response => ({
  type: types.WEIGHTPLAN_TREND_FULFILLED,
  response
});

const weightplanTrendRejected = () => ({
  type: types.WEIGHTPLAN_TREND_REJECTED
});

function getWeightplanTrend() {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(weightplanTrendFulfilled(response));
    const rejected = error => {
      dispatch(weightplanTrendRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(weightplanTrendPending());

    return wsCalls().getWeightplanTrend(fulfilled, rejected, getUserId());
  };
}

const weightTrendPending = () => ({
  type: types.WEIGHT_TREND_PENDING
});

const weightTrendFulfilled = response => ({
  type: types.WEIGHT_TREND_FULFILLED,
  response
});

const weightTrendRejected = () => ({
  type: types.WEIGHT_TREND_REJECTED
});

const programWeightTrendPending = () => ({
  type: types.PROGRAM_WEIGHT_TREND_PENDING
});

const programWeightTrendFulfilled = response => ({
  type: types.PROGRAM_WEIGHT_TREND_FULFILLED,
  response
});

const programWeightTrendRejected = () => ({
  type: types.PROGRAM_WEIGHT_TREND_REJECTED
});

const waistTrendPending = () => ({
  type: types.WAIST_TREND_PENDING
});

const waistTrendFulfilled = response => ({
  type: types.WAIST_TREND_FULFILLED,
  response
});

const waistTrendRejected = () => ({
  type: types.WAIST_TREND_REJECTED
});

function getWeightTrend(fromDate, toDate) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(weightTrendFulfilled(response));
    const rejected = error => {
      dispatch(weightTrendRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(weightTrendPending());

    return wsCalls().getWeightTrend(
      fulfilled,
      rejected,
      getUserId(),
      fromDate,
      toDate
    );
  };
}

function getWaistTrend(fromDate, toDate) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(waistTrendFulfilled(response));
    const rejected = error => {
      dispatch(waistTrendRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(waistTrendPending());

    return wsCalls().getWaistTrend(
      fulfilled,
      rejected,
      getUserId(),
      fromDate,
      toDate
    );
  };
}

function getProgramWeightTrend(fromDate, toDate) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response =>
      dispatch(programWeightTrendFulfilled(response));
    const rejected = error => {
      dispatch(programWeightTrendRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(programWeightTrendPending());

    return wsCalls().getWeightTrend(
      fulfilled,
      rejected,
      getUserId(),
      fromDate,
      toDate
    );
  };
}

export default {
  getProgramGoal,
  getWeightplanTrend,
  getWeightTrend,
  getWaistTrend,
  getProgramWeightTrend
};
