import types from './types';

const addIngredientGroup = name => ({
  type: types.ADD_INGREDIENT_GROUP,
  name
});

const addIngredient = (payload, removeFoodstuff, groupIndex, name) => ({
  type: types.ADD_INGREDIENT_IN_GROUP,
  payload,
  removeFoodstuff,
  groupIndex,
  name
});

const deleteIngredientGroup = ingredientGroupIndex => ({
  type: types.DELETE_INGREDIENT_GROUP,
  ingredientGroupIndex
});

const deleteIngredient = (ingredientGroupIndex, recipeIndex) => ({
  type: types.DELETE_INGREDIENT_IN_GROUP,
  ingredientGroupIndex,
  recipeIndex
});

const editIngredient = (payload, removeFoodstuff, ingredientGroupIndex) => ({
  type: types.EDIT_INGREDIENT_IN_GROUP,
  payload,
  removeFoodstuff,
  ingredientGroupIndex
});

const editIngredientGroupName = (newName, ingredientGroupIndex) => ({
  type: types.EDIT_INGREDIENT_GROUP_NAME,
  newName,
  ingredientGroupIndex
});

const reorderIngredients = items => ({
  type: types.REORDER_INGREDIENTS_IN_GROUP,
  items
});

export default {
  addIngredientGroup,
  addIngredient,
  editIngredient,
  editIngredientGroupName,
  deleteIngredient,
  deleteIngredientGroup,
  reorderIngredients
};
