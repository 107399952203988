import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const recipeSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH_INPUT_VALUE: {
      return {
        ...state,
        recipeSearchInputValue: action.value,
        recipeSearchFulfilled: false
      };
    }
    case types.SET_RAW_SEARCH_INPUT_VALUE: {
      return {
        ...state,
        recipeRawSearchInputValue: action.value,
        recipeSearchFulfilled: false
      };
    }
    case types.RECIPE_SEARCH_PENDING: {
      return {
        ...state,
        getStatus: requestStatus.pending
      };
    }
    case types.RECIPE_SEARCH_FULFILLED: {
      return {
        ...state,
        getStatus: requestStatus.fulfilled,
        recipeSearchPage: action.recipeSearchPage,
        recipeSearchItems: action.response
      };
    }
    case types.RECIPE_SEARCH_REJECTED: {
      return {
        ...state,
        getStatus: requestStatus.rejected
      };
    }
    case types.RESET_SEARCH_RESULT: {
      return {
        ...state,
        recipeSearchFulfilled: false,
        recipeSearchItems: []
      };
    }
    case types.RESET_INPUT_VALUE: {
      return { ...state, recipeSearchInputValue: '' };
    }
    case types.RESET_RAW_SEARCH_INPUT_VALUE: {
      return { ...state, recipeRawSearchInputValue: '' };
    }
    default:
      return state;
  }
};

export default recipeSearchReducer;
