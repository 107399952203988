import uniqueId from 'lodash/uniqueId';
import { recipeTypes } from '@ducks/recipe';
import types from './types';
import initialState from './initialState';

const recipeInstructionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_INSTRUCTION: {
      return {
        ...state,
        items: [
          ...state.items,
          {
            id: uniqueId(),
            text: action.value,
            type: action.instructionType
          }
        ],
        touched: true
      };
    }
    case types.DELETE_INSTRUCTION: {
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.id)
      };
    }
    case types.REORDER_INSTRUCTIONS: {
      return {
        ...state,
        items: action.items
      };
    }
    case types.EDIT_INSTRUCTION: {
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.id) {
            return { ...item, text: action.text };
          }
          return item;
        })
      };
    }
    case recipeTypes.RESET_RECIPE: {
      return initialState;
    }
    case recipeTypes.RECIPE_FULFILLED: {
      return initialState;
    }
    default:
      return state;
  }
};

export default recipeInstructionsReducer;
