export default {
  ADD_INGREDIENT_IN_GROUP: 'recipeIngredients/ADD_INGREDIENT_IN_GROUP',
  DELETE_INGREDIENT_IN_GROUP: 'recipeIngredients/DELETE_INGREDIENT_IN_GROUP',
  DELETE_INGREDIENT_GROUP: 'recipeIngredients/DELETE_INGREDIENT_GROUP',
  REORDER_INGREDIENTS_IN_GROUP:
    'recipeIngredients/REORDER_INGREDIENTS_IN_GROUP',
  EDIT_INGREDIENT_IN_GROUP: 'recipeIngredients/EDIT_INGREDIENT_IN_GROUP',
  EDIT_INGREDIENT_GROUP_NAME: 'recipeIngredients/EDIT_INGREDIENT_GROUP_NAME',
  ADD_INGREDIENT_GROUP: 'recipeIngredients/ADD_INGREDIENT_GROUP'
};
