import types from './types';
import initialState from './initialState';

const programGoalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PROGRAM_GOAL_PENDING: {
      return { ...state, programGoalPending: true };
    }
    case types.PROGRAM_GOAL_FULFILLED: {
      return {
        ...state,
        ...action.response
      };
    }
    case types.PROGRAM_GOAL_REJECTED: {
      return { ...state, programGoalPending: false };
    }
    case types.WEIGHTPLAN_TREND_PENDING: {
      return { ...state, weightplanTrendPending: true };
    }
    case types.WEIGHTPLAN_TREND_FULFILLED: {
      return {
        ...state,
        ...action.response
      };
    }
    case types.WEIGHTPLAN_TREND_REJECTED: {
      return { ...state, weightplanTrendPending: false };
    }
    case types.WEIGHT_TREND_PENDING: {
      return { ...state, weightTrendPending: true };
    }
    case types.WEIGHT_TREND_FULFILLED: {
      return {
        ...state,
        weightTrend: action.response
      };
    }
    case types.WEIGHT_TREND_REJECTED: {
      return { ...state, progamWeightTrendPending: false };
    }
    case types.PROGRAM_WEIGHT_TREND_PENDING: {
      return { ...state, progamWeightTrendPending: true };
    }
    case types.PROGRAM_WEIGHT_TREND_FULFILLED: {
      return {
        ...state,
        progamWeightTrend: action.response
      };
    }
    case types.PROGRAM_WEIGHT_TREND_REJECTED: {
      return { ...state, progamWeightTrendPending: false };
    }
    case types.WAIST_TREND_PENDING: {
      return { ...state, waistTrendPending: true };
    }
    case types.WAIST_TREND_FULFILLED: {
      return {
        ...state,
        waistTrend: action.response
      };
    }
    case types.WAIST_TREND_REJECTED: {
      return { ...state, waistTrendPending: false };
    }
    default:
      return state;
  }
};

export default programGoalReducer;
