import types from './types';
import initialState from './initialState';

const recipeInstructionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_IMAGE: {
      return {
        ...state,
        file: action.value
      };
    }
    case types.DELETE_IMAGE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default recipeInstructionsReducer;
