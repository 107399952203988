import types from './types';
import initialState from './initialState';

const weightplanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PARTIAL_GOAL_PENDING: {
      return { ...state, pending: true };
    }
    case types.GET_PARTIAL_GOAL_FULFILLED: {
      return {
        ...state,
        pending: false,
        partialGoals: action.response && action.response.partialGoals
      };
    }
    case types.GET_PARTIAL_GOAL_REJECTED: {
      return { ...state, pending: false };
    }
    default:
      return state;
  }
};

export default weightplanReducer;
