const sortedTags = tagsSections =>
  tagsSections.map(tagSection => ({
    ...tagSection,
    tags: tagSection.tags
      .map(tag => ({
        ...tag,
        weight:
          (tag.parentId || tag.id) +
          (!tag.parentId ? 0 : parseFloat(`0.${Math.abs(tag.id)}`)),
        type: tagSection.tagType
      }))
      .sort((tag1, tag2) => tag1.weight - tag2.weight)
  }));

export default { sortedTags };
