import types from './types';
import initialState from './initialState';

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_CONSENTS_FULFILLED: {
      return {
        ...state,
        allConsents: action.response
      };
    }
    case types.GET_ALL_CONSENTS_REJECTED: {
      return { ...state };
    }
    case types.GET_USER_CONSENTS_FULFILLED: {
      return {
        ...state,
        userConsents: action.response
      };
    }
    case types.GET_USER_CONSENTS_REJECTED: {
      return { ...state };
    }
    case types.PUT_USER_CONSENTS_PENDING: {
      return { ...state, putUserConsentsPending: true };
    }
    case types.PUT_USER_CONSENTS_FULFILLED: {
      return {
        ...state,
        putUserConsentsPending: false
      };
    }
    case types.PUT_USER_CONSENTS_REJECTED: {
      return { ...state, putUserConsentsPending: false };
    }
    default:
      return state;
  }
};

export default profileReducer;
